<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mt-2">
      <v-flex xs12 sm4 md4 lg4 v-if="!selected" class="pa-1">
        <v-select
          dense
          solo
          class="caption"
          :label="$t('select business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusiness"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>

      <v-flex xs12 sm4 md4 lg4 class="text-right pa-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="$t('search products')"
        ></v-text-field></v-flex
      ><v-spacer></v-spacer>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="white" @click="getSales()"
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh table") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="salesDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="salesDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              in
              solo
            ></v-text-field>
          </template>
          <v-date-picker v-model="salesDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"
              >{{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(salesDate);
                dateFilter();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :items="filteredSales"
          :items-per-page="20"
          :loading="loading"
          :search="search"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.businessId`]="{ item }">
            {{ getBusinessName(item.businessId) }}
          </template>
          <template v-slot:[`item.productName`]="{ item }">
            {{ getProductName(item.productId) }}
          </template>
          <template v-slot:[`item.dateTime`]="{ item }">
            {{ formatDate(item.dateTime) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom color="blue lighten-3">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  left
                  v-bind="attrs"
                  v-on="on"
                  @click="viewSale(item.id)"
                  :color="$store.state.primaryColor"
                  >visibility</v-icon
                >
              </template>
              <span>{{ $t("details") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import db from "@/plugins/fb";

export default {
  data: () => ({
    modal: false,
    loading: true,
    search: "",
    salesDate: format(new Date(), "yyyy-MM-dd"),

    selectedShop: [],
    filteredSales: [],
    sales: [],
    products: [],
  }),
  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },
    headers() {
      return [
        {
          text: this.$t("product id"),
          value: "productId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("product name"),
          value: "name",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("quantity sold"),
          value: "quantity",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("transaction amount"),
          value: "amount",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("transaction type"),
          value: "transactionType",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("transaction date"),
          value: "dateTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },

  mounted() {
    this.getProducts();
  },

  methods: {
    getSales() {
      this.loading = true;
      if (this.selected) {
        db.collection("transactions")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            //get todays sales
            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.sales.filter(
                (item) =>
                  format(item.dateTime.toDate(), "yyyy-MM-dd") ==
                  this.$store.state.todaysDate
              );
              this.filteredSales = filtered;
            } else {
              this.filteredSales = this.sales;
            }
            this.$store.commit("SET_TODAY_DATE", "");
            this.loading = false;
          });
      } else {
        db.collection("transactions")
          .where("businessId", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            //get todays sales
            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.sales.filter(
                (item) =>
                  format(item.dateTime.toDate(), "yyyy-MM-dd") ==
                  this.$store.state.todaysDate
              );
              this.filteredSales = filtered;
            } else {
              this.filteredSales = this.sales;
            }

            this.$store.commit("SET_TODAY_DATE", "");
            this.loading = false;
          });
      }
    },

    onSelectShop() {
      this.loading = true;
      this.sales = [];
      db.collection("transactions")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.sales.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.filteredSales = this.sales;
          this.loading = false;
        });
    },

    getProducts() {
      db.collection("products")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
      this.getSales();
    },

    dateFilter() {
      let filtered = this.sales.filter(
        (item) => format(item.dateTime.toDate(), "yyyy-MM-dd") == this.salesDate
      );
      this.filteredSales = filtered;
    },
    getProductName(id) {
      let prodId = this.products.find((item) => item.id == id);
      return prodId.name;
    },
    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      return bizId.businessName;
    },

    viewSale(saleID) {
      this.$router.push({ name: "ViewSaleDetails", params: { id: saleID } });
    },

    formatDate(val) {
      return format(val.toDate(), "EEE, dd MMMM, yyyy");
    },
  },
};
</script>

<style></style>
